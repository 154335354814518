import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescr: description
        defaultImg: image
      }
    }
  }
`;

type Props = {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
};

const HeadView: FC<Props> = ({
  title = null,
  description = null,
  image = null,
  article = false,
}) => {
  const { origin, href } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescr,
    defaultImg,
  } = site.siteMetadata;

  const head = {
    lang: 'pl-PL',
    title: title || defaultTitle,
    description: description || defaultDescr,
    image: origin + (image || defaultImg),
    url: href,
    type: article ? 'article' : 'website',
  };

  return (
    <Helmet titleTemplate={title ? titleTemplate : ''}>
      <html lang={head.lang} />
      <title>{head.title}</title>

      <meta name="description" content={head.description} />
      <meta name="image" content={head.image} />

      <meta property="og:title" content={head.title} />
      <meta property="og:type" content={head.type} />
      <meta property="og:description" content={head.description} />
      <meta property="og:url" content={head.url} />
      <meta property="og:image" content={head.image} />

      <link rel="stylesheet" href="fonts/fonts.css" />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Helmet>
  );
};

export default HeadView;
